<template>
    <div class="error-msg" :class="divClass">
      <ul class="mb-0 p-0 fs-14">
        <li v-for="(error, index) of errors" :key="index" class="d-block text-red">
          {{ error.$message }}
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      errors: {
        type: Array,
        required: true
      },
      divClass: {
        type: String,
        default: "",
      },
    }
  }
  </script>
  